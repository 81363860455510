const library = {
  state: () => ({
    editLibraryData: {},
    showLibraryAction: false,
    newassignmentDialog: false,
    isLoggedIn: false,
  }),

  getters: {
    editLibraryData(state) {
      return state.editLibraryData;
    },
    newassignmentDialog(state) {
      return state.newassignmentDialog;
    },
    showLibraryAction(state) {
      return state.showLibraryAction;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
  },

  mutations: {
    saveEditLibrary(state, data) {
      state.editLibraryData = data;
    },

    changeLibraryAction(state, data) {
      state.showLibraryAction = data;
    },
    openAssignmentDialog(state) {
      state.newassignmentDialog = true;
    },
    closeAssignmentDialog(state) {
      state.newassignmentDialog = false;
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value;      
    },
  },
};

export default library;
