const level = {
    state: () => ({ 
        userEditData: {},
        departmentList:[],
        editdeptList:[],
    }),

    getters: { 
        userEditData(state){
            return state.userEditData
        },
        departmentList(state) {
            return state.departmentList
        },
        editdeptList(state) {
            return state.editdeptList
        },
    },

    mutations: { 
        saveEditUserData(state,data){
            state.userEditData = data;
        },
        clearRemoveUserEditData(state){
            state.userEditData = {}
        },
        savedeparmentList(state, data) {
            state.departmentList = data;
        },
        editeparmentList(state, data) {
            state.editdeptList = data;
        },
    },
    
}

export default level;