import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  //Login
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/Login')
  },

  // Dashboard
  // {
  //   path: '/',
  //   name: 'dashboard',
  //   component: () => import('../views/Dashboard')
  // },

  // Home
  {
    path: '/home/library',
    name: 'home-library',
    component: () => import('../views/Home/Library/')
  },
  {
    path: '/home/library/:name',
    name: 'home-library-:name',
    component: () => import('../views/Home/Library/_library/')
  },
  {
    path: '/home/library/:name/:subName',
    name: 'home-library-:name-:subName',
    component: () => import('../views/Home/Library/_library/_subLibrary')
  },
  {
    path: '/home/lesson-learn',
    name: 'home-lesson-learn',
    component: () => import('../views/Home/LessonLearn/Index.vue')
  },
  {
    path: '/home/assignment-course',
    name: 'home-assignment-course',
    component: () => import('../views/Home/Assignment Course/AssignmentCourse.vue')
  },  
  {
    path: '/home/assignment-course/video-list',
    name: 'home-assignment-course-video-list',
    component: () => import('../views/Home/Assignment Course/videoList.vue')
  },
  {
    path: '/home/assignment-course/user-list',
    name: 'home-assignment-course-user-list',
    component: () => import('../views/Home/Assignment Course/userList.vue')
  },
  {
    path: '/home/assignment-course/courselist-report',
    name: 'home-assignment-course-courselist-report',
    component: () => import('../views/Home/Assignment Course/CourseListReport.vue')
  },
  {
    path: '/home/quiz',
    name: 'home-quiz',
    component: () => import('../views/Home/Quiz/Quiz.vue')
  },
  {
    path: '/home/addquiz/add-quiz',
    name: 'home-quiz-addquiz',
    component: () => import('../views/Home/Quiz/AddQuiz/AddQuiz.vue')
  },
  {
    path: '/home/editquiz/edit-quiz',
    name: 'home-quiz-editquiz',
    component: () => import('../views/Home/Quiz/EditQuiz/EditQuiz.vue')
  },
  {
    path: '/home/community',
    name: 'home-community',
    component: () => import('../views/Home/Community/Index.vue')
  },
  {
    path: '/home/community/expert-consultant',
    name: 'home-expert-consultant',
    component: () => import('../views/Home/Community/ExpertConsultant.vue')
  },
  {
    path: '/home/community/frequently-asked-question',
    name: 'home-frequently-asked-question',
    component: () => import('../views/Home/Community/FrequentlyAskedQuestion.vue')
  },
  {
    path: '/home/event',
    name: 'home-event',
    component: () => import('../views/Home/Event/Index.vue')
  },
  {
    path: '/home/event/preview/:id',
    name: 'home-event-preview-:id',
    component: () => import('../views/Home/Event/Preview.vue')
  },
  {
    path: '/home/event/participant/:id',
    name: 'home-event-participant-:id',
    component: () => import('../views/Home/Event/Participant.vue')
  },
  {
    path: '/home/feedback-box',
    name: 'home-feedback-box',
    component: () => import('../views/Home/FeedbackBox/Index.vue')
  },
  {
    path: '/home/banner',
    name: 'home-banner',
    component: () => import('../views/Home/Banner/banner.vue')
  },
  {
    path: '/home/store',
    name: 'home-store',
    component: () => import('../views/Home/Store/index.vue')
  },
  {
    path: '/home/store/article',
    name: 'home-store-article',
    component: () => import('../views/Home/Store/sub_Store/index.vue')
  },
  {
    path: '/home/store/sound',
    name: 'home-store-sound',
    component: () => import('../views/Home/Store/sub_Store/index.vue')
  },
  {
    path: '/home/store/video',
    name: 'home-store-video',
    component: () => import('../views/Home/Store/sub_Store/index.vue')
  },
  //Dashboards
  // {
  //   path: '/dashboards/dashboardsone',
  //   name: 'dashboards-dashboardsone',
  //   component: () => import('../views/Dashboards/Dashboardsone.vue')
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardNew.vue')
  },

  // Report
  {
    path: '/report/user-activity',
    name: 'report-user-activity',
    component: () => import('../views/Report/UserActivity.vue')
  },
  // {
  //   path: '/report/usage-metrics',
  //   name: 'report-usage-metrics',
  //   component: () => import('../views/Report/UsageMetrics.vue')
  // },
  {
    path: '/report/by-type-of-knowledge',
    name: 'report-by-type-of-knowledge',
    component: () => import('../views/Report/ByTypeOfKnowledge.vue')
  },
  {
    path: '/report/by-type-of-content',
    name: 'report-by-type-of-content',
    component: () => import('../views/Report/ByTypeOfContent.vue')
  },
  {
    path: '/report/content-viewing',
    name: 'report-content-viewing',
    component: () => import('../views/Report/ContentViewing.vue')
  },
  {
    path: '/report/requested-user',
    name: 'report-requested-user',
    component: () => import('../views/Report/RequestedUser.vue')
  },
  {
    path: '/report/assignment-course',
    name: 'report-assignment-course',
    component: () => import('../views/Report/AssignmentCourse.vue')
  },

  // User Management

  {
    path: '/user-management',
    name: 'user-management',
    component: () => import('../views/UserManagement/index.vue')
  },
  {
    path: '/user-management/member-list',
    name: 'user-management-member-list',
    component: () => import('../views/UserManagement/Group/MemberList.vue')
  },
  // {
  //Pemission List Page was change to dialog..
  //   path: '/user-management/permission-list',
  //   name: 'user-management-permission-list',
  //   component: () => import('../views/UserManagement/Level/Permission.vue')
  // },
  {
    path: '/company-profile',
    name: 'company-profile',
    component: () => import('../views/CompanyProfile/company-profile.vue')
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('../views/Inbox/Inbox.vue')
  },  
  //sendNotification
  {
    path: '/notification-page',
    name: 'notification-page',
    component: () => import('../views/Notification/notification_page.vue')
  },
  // Master Data
  {
    path: '/MasterData/type-of-content',
    name: 'master-data-type-of-content',
    component: () => import('../views/MasterData/TypeOfContent.vue')
  },
  {
    path: '/MasterData/type-of-knowledge',
    name: 'master-data-type-of-knowledge',
    component: () => import('../views/MasterData/TypeOfKnowledge.vue')
  },
  //setting
  {
    path: '/setting/notificationpage',
    name: 'setting-notificationpage',
    component: () => import('../views/Setting/notificationpage.vue')
  },
  {
    path: '/setting/emailpage',
    name: 'setting-emailpage',
    component: () => import('../views/Setting/emailpage.vue')
  },
  //Deeplink
  {
    path: '/home/DeepLink',
   name: 'home-DeepLink',
    component: () => import('../views/Home/DeepLink/DeepLink.vue')
  },
  {
    path: '/home/store/article/articleview',
    name: 'articleview',
    component: () => import('../views/Home/Store/sub_Store/store_Article/articleview-index.vue')
  },
  //Training Roadmap (iso)
  {
    path: '/trainingroadmap/skillchart',
    name: 'trainingroadmap-skillchart',
    component: () => import('../views/TrainingRoadmap/SkillChart/SkillChart.vue')
  },
  {
    path: '/trainingroadmap/settingsection',
    name: 'trainingroadmap-settingsection',
    component: () => import('../views/TrainingRoadmap/SettingSection/SettingSection.vue')
  },
  {
    path: '/trainingroadmap/settingposition',
    name: 'trainingroadmap-settingposition',
    component: () => import('../views/TrainingRoadmap/SettingPosition/SettingPosition.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //const token = localStorage.getItem("vivek_token");
  const token = sessionStorage.getItem("token");
  if (!token && to.path != "/login") next("/login");
  else next();
});


export default router
