const sound = {
    state: () => ({
        editData: {},
        showFirstTimeEditDataForSound: true
    }),

    getters:{
        editData(state){
            return state.editData
        }
    },

    mutations:{
        saveEditSound(state,data){
            state.editData = data
        },
        removeFirstTimeEditDataForSound(state){
            state.showFirstTimeEditDataForSound = false
        }
    }
}

export default sound;