<template>
  <v-app>
    <Navbar
      ref="navbar_comp"
      v-show="showNav"
      v-if="$route.path == '/login' ? false : true"
      :notiCount="notiCount"
      @updateNotiCount="updateNotiCount"
    />
    <v-main class="bg">
      <router-view
        @updateMethodCall="updateMethodCall"
        :style="$vuetify.breakpoint.mdAndDown ? 'padding-top: 1rem !important; padding-left: 0 !important' : 'padding-top: 1rem !important; padding-left: 1.7rem !important'"
      ></router-view>
    </v-main>
  </v-app>
</template>

<style scoped lang="css">
.bg {
  background: url("~@/assets/Component 87 – 6@2x.png") no-repeat center center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transform: scale(1);
}
</style>

<script>
import Navbar from "./components/Navbar";

export default {
  name: "App",

  components: {
    Navbar,
  },

  data: () => ({
    showNav: false,
    notiCount: 0,
  }),
  methods: {
    updateNotiCount(notiCount) {
      this.notiCount = notiCount;
    },
    updateMethodCall(v) {
      //hide nav bar when start loading Log In page and to show after user log in.
      if (v == "userLogIn") {
        this.showNav = true;
      } else {
        this.$refs.navbar_comp.call_permissions();
      }
    },
  },
};
</script>
