<template>
  <div>
    <v-dialog width="600" v-model="dialog">
      <v-card>
        <!-- secondary white--text-->
        <v-card-title>
          <v-row justify="start" no-gutters>
            <p style="font-size: 30px; color:#363636;">{{ $t("deleteconfirm") }}</p>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p class="pt-4 text-left" style="font-size: 16px; color: #363636">
            {{ text }}
          </p>
          <div v-if="ispartcipant">
            <v-divider></v-divider>
            <v-row>
              <p
                style="
                  color: #47484b;
                  font-size: 16px;
                  padding-left: 10px;
                  padding-top: 20px;
                "
              >
                {{ $t("sendnotitouser") }}
              </p>
              <v-checkbox
                class="chk-input"
                v-model="noti.inbox"
                label="Inbox"
                color="#a6cc39"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="noti.pushnoti"
                color="#a6cc39"
                class="chk-input"
                label="Push Notification"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="noti.email"
                color="#a6cc39"
                label="Email"
                class="chk-input"
                hide-details
              ></v-checkbox>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-4"
            text
            style="border: 1px solid #C0C0C0;border-radius: 8px;color: #424242;font-size: 20px"
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#FF6060"
            style="font-size: 20px;border-radius: 8px;"
            class="text-capitalize white--text mb-4"
            width="100"
            @click="$emit('confirm',ispartcipant==true?noti:null)"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: null,
    },
    ispartcipant: {
      type: Boolean,
      default: false,
    },
    // inbox: {
    //   type: Boolean,
    //   default: false,
    // },
    // pushnoti: {
    //   type: Boolean,
    //   default: false,
    // },
    // email: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data: () => ({
    noti: {
      inbox: false,
      pushnoti: false,
      email: false,
    },
  }),
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 10px !important;/**35px */
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: #47484b;
}
</style>