const lessonlearn = {
    state: () => ({
        editLessonLearnData: {},
        showFirstTimeEditDataForLessonLearn: true
    }),

    getters:{
        editLessonLearnData(state){
            return state.editLessonLearnData
        },
        showFirstTimeEditDataForLessonLearn(state){
            return state.showFirstTimeEditDataForLessonLearn
        }
    },

    mutations:{
        saveEditLessonLearn(state,data){
            state.editLessonLearnData = data
        },

        changeLessonLearnGroup(state,data){
            state.editLessonLearnData.isUserGroup = data;
        },
        
        showFirstTimeEditDataForLessonLearn(state){
            state.showFirstTimeEditDataForLessonLearn = true
        },

        removeFirstTimeEditDataForLessonLearn(state){
            state.showFirstTimeEditDataForLessonLearn = false
        }
    }
}

export default lessonlearn;